<template>
  <div>
    <page-loader :loading="infocardsIsLoading">
      <template v-if="hasNoLocations">
        <b-card>
          <div v-if="admin" class="d-flex justify-content-center flex-column">
            <h3 class="text-center text-muted">
              {{ T("Web.Generic.Locations.NoLocations") }}
            </h3>
            <h6 class="text-center text-muted">
              {{ T("Web.OrderingPage.CreateLocationInfo", "You need to create a location in order to create an infocard.") }}
            </h6>
          </div>
          <div v-else class="d-flex justify-content-center flex-column">
            <h3 class="text-center text-muted">
              {{ T("Web.Generic.Locations.NotAccessToLocation", "Looks like you do not have access to any locations, contact your admin to get access.") }}
            </h3>
          </div>
        </b-card>
      </template>
      <infocards-list
        :infocards="infocards"
        :filters="filters"
        :orderable-mode="true"
        :order-list="orderList"
        @infocard-orders-show-clicked="openInfocardOrdersModal"
        v-else
      />
    </page-loader>
    <infocard-orders-list-modal ref="infocardOrdersListModal" />
    <create-orders-modal ref="createOrdersModal" />
    <transition name="fade" mode="out-in">
      <order-footer
        v-if="orderList.length > 0"
        :order-list="orderList"
        @create-orders-clicked="openCreateOrdersModal"
      />
    </transition>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import PageLoader from "@/app/components/layout/PageLoader.vue";
import InfocardsList from "../background-data/infocards/components/InfocardListing/InfocardsList.vue";
import InfocardOrdersListModal from "./components/InfocardOrdersListModal.vue";
import CreateOrdersModal from "./components/CreateOrdersModal.vue";
import OrderFooter from "../background-data/infocards/components/OrderFooter.vue";

export default {
  components: {
    PageLoader,
    InfocardsList,
    InfocardOrdersListModal,
    CreateOrdersModal,
    OrderFooter,
  },
  async created() {
    await this.fetchInfocards({
      params: { locationId: this.currentLocation?.id },
    });
    this.filters = {
      locationId: this.currentLocation?.id,
      type: ["ContainerInfoCard"],
      fractions: [],
      fractionsOptions: [],
      locationId: null,
      ewcCodes: [],
      ewcCodesOptions: [],
      categories: [],
      categoriesOptions: [],
    };
    this.filters.fractions = this.infocards.reduce((pre, cur) => {
      let val = cur.wasteFractionName == null ? "null" : cur.wasteFractionName;
      if (!pre.includes(val)) pre.push(val);
      return pre;
    }, []);
    this.filters.fractionsOptions = this.filters.fractions;
    this.filters.ewcCodes = this.infocards.reduce((pre, cur) => {
      let val = cur.ewcCode == null ? "null" : cur.ewcCode;

      if (!pre.includes(val)) pre.push(val);
      return pre;
    }, []);
    this.filters.ewcCodesOption = this.filters.ewcCodes;
    this.filters.categories = this.infocards.reduce((pre, cur) => {
      let val = cur.wasteCategory == null ? "null" : cur.wasteCategory;

      if (!pre.includes(val)) pre.push(val);
      return pre;
    }, []);
    this.filters.categoriesOptions = this.filters.categories;
  },
  data() {
    return {
      filters: undefined,
      orderList: [],
    };
  },
  computed: {
    ...mapGetters({
      infocards: "infocards/list",
      infocardsIsLoading: "infocards/isFetchingList",
      currentLocation: "appUser/getCurrentLocationScope",
      locations: "locations/list",
    }),
    hasNoLocations() {
      return this.locations.lenght == 0;
    },
  },
  methods: {
    ...mapActions({ fetchInfocards: "infocards/fetchList" }),
    async openInfocardOrdersModal(infocard) {
      await this.$refs.infocardOrdersListModal.open(infocard);
    },
    async openCreateOrdersModal() {
      if (await this.$refs.createOrdersModal.open(this.orderList))
        this.orderList = [];
    },
  },
  watch: {
    async currentLocation(location) {
      await this.fetchInfocards({ params: { locationId: location?.id } });
      this.filters.locationId = location != null ? location.id : null;
    },
    infocards() {},
  },
};
</script>