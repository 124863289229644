<template>
  <div>
    <confirm-modal ref="confirmModal" />

    <b-modal
      id="booststrapmodal"
      :title="T('Web.OrderingPage.OrderPopup.Title', 'Create new order')"
      centered
      :visible="visible"
      @hidden="handleModalHidden"
      :no-close-on-backdrop="true"
      :hide-header-close="true"
      size="lg"
    >
      <div class="create-order-modal order-list">
        <b-table responsive :fields="tableContent.fields" :items="orders">
          <template #cell(name)="data">
            <div class="d-flex">
              <b-avatar
                v-if="data.item.infocard.coverPath"
                :src="apiBaseUrl() + '/images/' + data.item.infocard.coverPath"
                class="mr-1"
                square
              />
              <b-avatar
                square
                v-else
                src="@/assets/images/container-no-image.jpg"
                class="mr-1"
              />
              <span class="d-flex flex-column">
                <strong>{{ data.item.infocard.name }}</strong>
                <span
                  ><small>{{T('Web.Generic.Infocards.Ref', 'Ref')}}:{{ data.item.infocard.refId }}</small></span
                >
                <span>
                  <small v-if="data.item.infocard.wasteFractionName != null"
                    >{{ data.item.infocard.wasteFractionName }},</small
                  >
                  <small v-if="data.item.infocard.size > 0">
                    {{ data.item.infocard.size | toCurrency }}
                    {{ getContainerSizeUnit(data.item.infocard.sizeUnit).text }}
                  </small>
                </span>
              </span>
            </div>
          </template>
          <template #cell(location)="data">
            <span
              :set="
                (location = locations.find(
                  (l) => l.id == data.item.infocard.locationId
                ))
              "
            >
              <span
                ><small class="font-weight-bold">{{
                  location.name
                }}</small></span
              >
              <br />
              {{ location.address }} {{ location.zip }} {{ location.city }}
            </span>
          </template>
          <template #cell(options)="data">
            <div class="d-flex flex-column">
              <weekday-picker
                v-if="data.item.infocard.pickupDays.length > 0"
                class="weekday-picker"
                :value="data.item.infocard.pickupDays"
                :disabled="true"
              />
              <span class="text-primary font-weight-bold" v-else>{{
                T("Web.Generic.Ordering.PickupFastAsPossibleOption", "As fast as possible")
              }}</span>
              <transition name="fade" mode="out-in">
                <div v-if="!data.item.showAttachments">
                  <input
                    type="file"
                    @change="setFiles($event, data)"
                    multiple
                    class="my-1"
                  />
                  <b-form-textarea
                    :placeholder="T('Web.Generic.Ordering.Comment')"
                    v-model="data.item.comment"
                  />
                </div>
                <b-button
                  class="mt-1"
                  variant="light"
                  @click="data.item.showAttachments = true"
                  size="sm"
                  v-else
                  >{{ T("Web.Generic.AddAttachment", "Add attachment") }}
                  <feather-icon icon="PaperclipIcon"
                /></b-button>
              </transition>
            </div>
          </template>
        </b-table>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            class="float-right"
            :disabled="submitting"
            @click="handleSubmit"
          >
            {{
              submitting
                ? T('Web.OrderingPage.OrderPopup.Ordering', 'Ordering...')
                : T('Web.OrderingPage.OrderPopup.Order', 'Order')
            }}
          </b-button>
          <b-button
            variant="light"
            class="float-right mr-2"
            @click="handleCancel"
            :disabled="submitting"
          >
            {{ T("Web.Generic.Cancel") }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BButton, BAvatar, BTable, BFormTextarea } from "bootstrap-vue";
import FlexTable from "@/components/flex-table/FlexTable.vue";
import WeekdayPicker from "@/components/forms/WeekdayPicker.vue";
import OrdersService from "@/services/collection/orders-service";
import { mapActions, mapGetters } from "vuex";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";

export default {
  components: {
    BModal,
    BButton,
    FlexTable,
    BAvatar,
    BTable,
    BFormTextarea,
    WeekdayPicker,
    ConfirmModal,
  },
  data() {
    return {
      visible: false,
      submitting: false,
      resolvePromise: undefined,
      rejectPromise: undefined,
      orders: [],
      infoCards: [],
    };
  },
  methods: {
    ...mapActions({
      fetchInfoCard: "infoCards/fetchSingleOfType",
      createOrders: "ordering/createMultiple",
    }),
    open(infoCards) {
      this.visible = true;
      this.orders = infoCards.map((i) => {
        return {
          infoCardId: i.id,
          infocard: i,
          comment: "",
          showAttachments: false,
        };
      });
      this.infoCards = infoCards;
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    setFiles(event, data) {
      data.item.files = event.target.files;
    },
    handleModalHidden() {
      this.visible = false;
      this.infoCards = [];
    },
    async handleSubmit() {
      try {
        this.submitting = true;
        await this.createOrders(this.orders);
        this.resolvePromise(true);
        this.handleModalHidden();
      } finally {
        this.submitting = false;
      }
    },
    handleCancel() {
      this.resolvePromise(false);
      this.handleModalHidden();
    },
  },
  computed: {
    ...mapGetters({ locations: "locations/list" }),
    tableContent() {
      return {
        fields: [
          {
            key: "name",
            label: this.T("Web.Generic.Infocards.InfocardName"),
            sortable: false,
          },
          {
            key: "location",
            label: this.T("Web.Generic.Address"),
            sortable: false,
          },
          {
            key: "infocard.collectorName",
            label: this.T("Web.Generic.Suppliers.Supplier"),
            sortable: true,
          },
          {
            key: "options",
            label: "",
            sortable: false,
          },
        ],
      };
    },
  },
};
</script>
<style lang="scss">
.modal-lg,
.modal-xl {
  max-width: 1035px;
}
@media (max-width: 648px) {
  .create-order-modal.order-list {
    .table-responsive {
      overflow-x: hidden;
    }
    .table {
      display: flex;
      thead {
        display: none;
      }
      tbody {
        display: flex;
        flex-direction: column;
        width: 100%;
        tr {
          display: flex;
          flex-direction: column;
          border-bottom: 1px solid #ebe9f1;
          padding: 15px 0;
          td {
            border: none;
            padding: 0;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.weekday-picker {
  margin-top: 10px;
}
.no-weekday-to-show-text {
  font-weight: bold;
  color: #30cf84;
}
</style>