<template>
  <div class="order-footer">
    <b-container class="d-flex justify-content-between align-items-center">
      <span class="d-flex flex-column">
        <span><small class="font-weight-bold">{{T('Web.OrderingPage.OrderFooter.Title', 'Order')}}</small></span>
        <span>{{ orderList.length }} {{T('Web.OrderingPage.OrderFooter.InfocardsSelected', 'infocard(s) selected')}}</span>
      </span>
      <span>
        <b-button variant="primary" @click="$emit('create-orders-clicked')"
          >{{T('Web.OrderingPage.OrderFooter.OrderNow', 'Order now')}}
          <feather-icon icon="ClipboardIcon" />
        </b-button>
      </span>
    </b-container>
  </div>
</template>
<script>
import { BButton, BContainer } from "bootstrap-vue";
export default {
  props: {
    orderList: {
      required: true,
    },
  },
  components: {
    BButton,
    BContainer,
  },
};
</script>
<style lang="scss" scoped>
.order-footer {
  position: fixed;
  bottom: 0;
  background-color: #fff;
  z-index: 10;
  border-top: 2px solid #f4f4fb;
  padding: 5px 15px;
  margin-left: -2rem;
  width: calc(100vw - (100vw - 100%) - calc(2rem * 2) - 204px);
  transition: 300ms ease all, background 0s;
  box-shadow: 15px 0 0 0 rgb(34 41 47 / 5%);
}

.vertical-layout.vertical-menu-modern.menu-collapsed {
  .order-footer {
    width: calc(100vw - (100vw - 100%) - 4.4rem - 18px);
  }
}

@media only screen and (max-width: 1200px) {
  .order-footer {
    width: 100%;
  }
}
@media only screen and (max-width: 575px) {
  .order-footer {
    margin-left: -1rem;
  }
}
</style>