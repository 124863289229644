<template>
  <div>
    <order-image-modal ref="orderImageModal" />
    <confirm-modal ref="confirmModal" />

    <b-modal
      id="booststrapmodal"
      :title="infoCard.name"
      centered
      :visible="visible"
      @hidden="handleModalHidden"
      size="lg"
    >
      <div class="modals-content">
        <div class="body">
          <b-table
            v-if="orders.items != undefined"
            class="order_table"
            responsive
            :fields="tableContent.fields"
            :items="orders.items"
            show-empty
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :empty-text="T('Web.Generic.TableEmpty', 'No items to show')"
          >
            <template #cell(comment)="data">
              {{
                data.item.comment
                  ? data.item.comment
                  : T("Web.Generic.NoComment", "No comment")
              }}
            </template>
            <template #cell(orderDate)="data">
              {{ format_datetime(data.item.orderDate) }} <br />
              <small>{{ data.item.orderer.name }}</small>
            </template>
            <template #cell(status)="data">
              <span v-if="data.item.status == 'Created'">
                {{
                  T("Web.Generic.Ordering.Status.NotApproved", "Not approved")
                }}
              </span>
              <span v-else-if="data.item.status == 'Accepted'">
                {{ T("Web.Generic.Ordering.Status.Approved", "Approved") }}
                <br />
                <small>{{ format_datetime(data.item.acceptedAt) }}</small>
              </span>
              <span v-else-if="data.item.status == 'Canceled'">
                {{ T("Web.Generic.Ordering.Status.Canceled", "Canceled") }}
                <br />
                <small>{{ format_datetime(data.item.canceledAt) }}</small>
              </span>
              <span v-else>
                {{ T("Web.Generic.Ordering.Status.Emptied", "Emptied") }}
                <br />
                <small>{{ format_datetime(data.item.completedAt) }}</small>
              </span>
            </template>
            <template #cell(action)="data">
              <div class="flex-end">
                <icon-badge
                  @click="openImageModel(data.item)"
                  icon="ImageIcon"
                  v-b-tooltip.hover.top="
                    T('Web.OrderingPage.InfocardOrdersPopup.SeeOrUploadImages', 'See/upload images')
                  "
                />
                <icon-badge
                  v-if="data.item.status == 'Created'"
                  @click="handleCancelOrderClicked(data.item.id)"
                  class="mr-1 remove"
                  icon="XIcon"
                  v-b-tooltip.hover.top="T('Web.Generic.Cancel')"
                />
                <icon-badge
                  v-if="data.item.status == 'Accepted'"
                  @click="handleEmptiedSendClicked(data.item.id)"
                  class="mr-1"
                  icon="CheckIcon"
                  v-b-tooltip.hover.top="
                    T('Web.OrderingPage.InfocardOrdersPopup.MarkAsCollected', 'Mark as collected')
                  "
                />
              </div>
            </template>
          </b-table>
          <div class="d-flex justify-content-center">
            <b-pagination
              v-if="orders.paging != undefined"
              v-model="currentPage"
              :total-rows="orders.paging.totalItems"
              :per-page="perPage"
              aria-controls="my-table"
            ></b-pagination>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <div class="w-100">
            <b-button
              variant="light"
              class="float-right"
              @click="handleModalHidden"
            >
              {{ T("Web.Generic.Close") }}
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import dragablemodal from "@/components/layout/dragablemodal.vue";
import { BModal, BTable, BButton, VBTooltip, BPagination } from "bootstrap-vue";
import IconBadge from "@/components/IconBadge.vue";
import OrdersService from "@/services/collection/orders-service";
import OrderImageModal from "@/components/modals/infoCards/OrderImageModal.vue";
import authHelper from "@/auth";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
import { mapActions } from "vuex";

export default {
  components: {
    dragablemodal,
    BPagination,
    BTable,
    BButton,
    IconBadge,
    BModal,
    OrderImageModal,
    ConfirmModal,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  async created() {
    this.service = new OrdersService();
  },
  data() {
    return {
      accountId: null,
      service: null,
      currentPage: 1,
      perPage: 15,
      visible: false,
      resolvePromise: undefined,
      rejectPromise: undefined,
      infoCard: {},
      orders: [],
      sortBy: "createdDate",
      sortDesc: true,
    };
  },
  methods: {
    ...mapActions({ reloadInfocard: "infocards/fetchSingle" }),
    async open(infoCard) {
      this.infoCard = infoCard;
      this.accountId = authHelper.getAccountId();
      await this.fetchlistOrdersOnInfoCard();

      this.visible = true;

      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    async handleCancelOrderClicked(orderId) {
      if (!confirm("Are you sure?")) return;
      await this.service.cancelOrderFor(orderId);
      this.fetchlistOrdersOnInfoCard();
      await this.reloadInfocard({ id: this.infoCard.id });
    },

    async handleEmptiedSendClicked(orderId) {
      await this.service.markasEmptyForCollectorOrder(orderId, this.accountId);
      this.fetchlistOrdersOnInfoCard();
    },

    async fetchlistOrdersOnInfoCard() {
      let response = await this.service.listOrdersOnInfoCard(this.infoCard.id, {
        page: this.currentPage,
        perPage: this.perPage,
      });
      this.orders = response;
    },
    handleModalHidden() {
      this.orders = [];
      this.infoCard = {};
      this.visible = false;
    },
    handleCancel() {
      this.resolvePromise(false);
      this.handleModalHidden();
    },

    async openImageModel(order) {
      await this.$refs.orderImageModal.open(order);
    },
  },
  computed: {
    tableContent() {
      return {
        fields: [
          {
            key: "requisitionId",
            label: this.T('Web.Generic.Ordering.RequisitionId'),
          },
          {
            key: "quantity",
            label: this.T('Web.Generic.Quantity', 'Quantity')
          },
          {
            key: "comment",
            label: this.T("Web.Generic.Ordering.Comment"),
          },
          {
            key: "orderDate",
            label: this.T("Web.Generic.Ordering.OrderingDate", "Ordering date"),
            sortable: true,
          },
          {
            key: this.T("Web.Generic.Status"),
            label: "status",
            sortable: true,
          },

          {
            key: "action",
            label:"",
          },
        ],
      };
    },
  },
  watch: {
    async currentPage() {
      await this.fetchlistOrdersOnInfoCard({
        params: this.closedInvoicesParams,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
span {
  &.remove {
    cursor: pointer;
    &.hover-effect:hover {
      background-color: #cf4330;
    }
  }
}

.flex-end {
  display: flex;
  justify-content: space-evenly;
}
</style>
<style lang="scss">
.order_table {
  .table {
    td {
      border-top: unset !important;
      border-right: unset !important;
      border-left: unset !important;
      border-bottom: 1px solid #ebe9f1 !important;
    }
  }
}
</style>

